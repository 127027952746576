import "../styles/store.css";
import "../styles/login.css";
import React, { useState, useEffect } from 'react';
import { viewStore } from "../server";

import crystal from "../assets/500px_Crystal.png";

export default function Store(props) {
  const [storeItems, setStoreItems] = useState([]);

  async function getStoreItems() {
    const store = await viewStore()
    setStoreItems(store)
  }

  async function viewItem(cost, image, id, name) {
    props.setViewItem(true);
    props.setItemCost(cost);
    props.setItemImage(image);
    props.setItemId(id);
    props.setItemName(name)
  }

  useEffect(() => {
    getStoreItems()
  }, [])

  return (
    <div className="store">
      <div style={{ padding: "1rem", fontSize: "2rem" }} className="LoginTitle">
        Enter Raffles

        <div style={{ textAlign: "center", padding: "1rem", fontSize: "1rem" }}>JOIN THE RAFFLES AND WIN AWESOME DAILY PRIZES!</div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
        <div style={{ color: "white", fontWeight: 1000, background: "#414141", padding: "1rem", borderRadius: "1rem", border: "2px lime solid" }}>🟩 - Active</div>
        <div style={{ color: "white", fontWeight: 1000, background: "#414141", padding: "1rem", borderRadius: "1rem", border: "2px red solid" }}>🟥 - Not Active</div>
      </div>

      <div className="store_map">
        {storeItems ? storeItems.map((item, i) =>
          <>
            <div style={{ border: `2px ${item.active ? "lime" : "red"} solid` }} className="store_card">
              <div style={{ backgroundImage: `url("${item.image}")`, backgroundPosition: "center", height: "180px" }} className="CardTopDisplay">
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                  <button title={"Raffle ID"} className="Detail_Button">{item.raffle_id}</button>
                  <button title={"Terms & Conditions"} className="Detail_Button">T&C</button>
                </div>

                <ItemName item={item.item} />
              </div>

              <Button
                key={item.raffle_id}
                amount={item.amount}
                id={item.raffle_id}
                image={item.image}
                name={item.item}
              />
            </div>
          </>
        ) : (
          <div>Loading Raffles...</div>
        )}
      </div>
    </div >
  )

  function Button(props) {

    return (
      <div key={props.key} style={{ display: "flex", justifyContent: "space-around", marginBottom: "1rem" }}>
        <button onClick={() => viewItem(props.amount, props.image, props.key, props.name)}
          style={{ display: "flex", alignItems: "center", padding: "1rem" }}
          className="LoginButton">Enter
          <img alt="" height={25} src={crystal}></img>
          {props.amount}
        </button>

        <button className="Detail_Button">❤️</button>
      </div>
    )
  }

  function ItemName(props) {

    return (
      <div style={{ padding: "2rem" }} className="StoreCardTitle">
        <div style={{ fontWeight: 1000 }}>{props.item}</div>
      </div>
    )
  }
}
