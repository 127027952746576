import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
  increment,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
  orderBy,
  doc
} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';


const firebaseConfig = {
  apiKey: "AIzaSyA6_fIKomL6avaDZhM1PuPFovndsBZGZQI",
  authDomain: "kick-gwardo.firebaseapp.com",
  projectId: "kick-gwardo",
  storageBucket: "kick-gwardo.appspot.com",
  messagingSenderId: "216232112843",
  appId: "1:216232112843:web:eb31d933dfb8543b8f214e"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export async function fetchUserInfo(user) {
  if (!user) return;
  console.log("Users Username")
  console.log(user.username)
  // Checking for a user in the database
  const userSearch = doc(db, "users", user.username);
  const userProfile = await getDoc(userSearch);

  if (userProfile.exists()) {
    console.log("Document data:", userProfile.data());
    const user = userProfile.data();
    return user;
  } else {
    console.log("No such document!");
    return await addUser(user)
  }
}

export async function addUser(user, streams) {
  const code = uuidv4();

  // Adding a file to the database
  try {
    await setDoc(doc(db, "users", user.username), {
      username: user.username,
      kickLinked: false,
      balance: 0,
      createdAt: serverTimestamp(),
      kickCode: code.slice(0, 8),
      kickUser: { user },
      initialClaim: false,
      lastClaim: null,
      claims: [],
      transactions: [],
    }, { merge: true })

    const userSearch = await doc(db, "users", user.username);
    const userProfile = await getDoc(userSearch);

    console.log(userProfile.data())
    if (userProfile.exists()) {
      return userProfile.data();
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function isDiscordLinked(user) {
  const userSearch = doc(db, "users", user);
  const userProfile = await getDoc(userSearch);

  if (userProfile.exists()) {
    const user = await userProfile.data();

    return user;
  }
}

export async function claimBonus(user) {
  const userSearch = doc(db, "users", user);

  await updateDoc(userSearch, {
    balance: increment(5),
    initialClaim: true,
    lastClaim: serverTimestamp(),
    // claim: arrayUnion([{ claimed: 5, date: serverTimestamp() }])
  });

  // Checking for a user in the database
  const userBalanceUpdate = doc(db, "users", user);
  const userProfile = await getDoc(userBalanceUpdate);

  if (userProfile.exists()) {
    return userProfile.data()
  }
}

export async function kickHelper(user) {
  const confirmation = await confirmBalance(user)

  if (confirmation === true) {
    const userSearch = doc(db, "users", user);

    await updateDoc(userSearch, {
      balance: increment(-15)
    });

    // Checking for a user in the database
    const userBalanceUpdate = doc(db, "users", user);
    const userProfile = await getDoc(userBalanceUpdate);

    if (userProfile.exists()) {
      return userProfile.data()
    }
  } else return false;
}

async function confirmBalance(user) {
  // Checking for a user in the database
  const userBalanceUpdate = doc(db, "users", user);
  const userProfile = await getDoc(userBalanceUpdate);

  if (userProfile.exists()) {
    if (userProfile.data().balance >= 1) {
      return true;
    } else return false;
  }
}

export async function viewStore() {
  const storeRef = query(collection(db, "store"), where("active", "==", true), orderBy("raffle_id", "desc"));
  let storeItems = [];
  const storeSnapshot = await getDocs(storeRef)
  storeSnapshot.forEach((items) => {
    console.log(items.data())
    storeItems.push(items.data())
  })
  const storeItemsReturn = await Promise.all(storeItems)
  return storeItemsReturn;
}

export async function addItemToStore(
  image,
  name,
  cost,
  raffleId,
) {
  const docData = await setDoc(doc(db, "store", name), {
    image: image,
    item: name,
    amount: cost,
    raffle_id: raffleId,
    active: true
  }, { merge: true })

  return docData;
}