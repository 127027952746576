import "../styles/kickhelper.css";
import "../styles/login.css";
import React, { useState, useEffect } from 'react';
import { kickHelper } from "../server";
import axios from "axios";
import kickLogo from "../assets/kick_logo.svg";

export default function KickHelper(props) {
  const [ask, setAsk] = useState("");
  const [questions, setQuestions] = useState("");
  const [responses, setResponses] = useState([]);
  const [confirmAIBuy, setConfirmAIBuy] = useState(false);
  const [newBalance, setNewBalance] = useState(0);
  const [balanceChanged, setBalanceChanged] = useState(false);
  const [thinking, setThinking] = useState(false);

  async function askKickHelper(newBalance) {
    setQuestions(ask)
    setThinking(true)
    const options = {
      method: "POST",
      url: "https://api.edenai.run/v2/text/chat",
      headers: {
        authorization: "Bearer " + process.env.REACT_APP_EDEN_AI,
      },
      data: {
        output_sentences: 1,
        providers: "openai",
        chatbot_global_action: "Act as an live streaming coach, only answer questions about live streaming.",
        text: ask,
        language: "en",
      },
    };

    await axios.request(options).then((data) => {
      setNewBalance(newBalance)
      setThinking(false)
      setResponses(data.data.openai.message)
    }).catch((err) => console.log(err))
  }

  async function kickinitiate() {
    const validateAIUsage = await kickHelper(props.user)
    if (validateAIUsage !== false) {
      setConfirmAIBuy(!confirmAIBuy)
      askKickHelper(validateAIUsage.balance)
      setBalanceChanged(true)
    } else {
      alert("You do not have enough balance!")
      setConfirmAIBuy(!confirmAIBuy)
      return;
    }
  }

  function backButton() {
    props.setViewAI(false)
    if (balanceChanged) {
      props.setBalance(newBalance)
    }
  }

  useEffect(() => {
    setResponses([])

  }, [ask])

  return (
    <>
      <div className="helper">
        <button style={{ maxWidth: "100px", marginLeft: "1rem", marginTop: "1rem", marginBottom: "1rem" }} onClick={() => backButton()} className="LoginButton">Back</button>

        <div className="KickHelper">
          {responses.length === 0 && ask && (
            <div className="message">
              <div style={{ fontWeight: "bolder", color: "lime", paddingBottom: "1rem" }}>
                User
              </div>

              <div>
                {ask}
              </div>
            </div>
          )}

          {responses && responses.map(response =>
            <>
              {response.role === "user" && (
                <>
                  <div className="message">
                    <div style={{ textAlign: "right", color: "lime", fontWeight: "bolder" }}>You</div>
                    {response.message === questions && (
                      <div style={{ textAlign: "right", marginTop: "0.5rem", fontWeight: "lighter" }}>{response.message}</div>
                    )}
                  </div>
                </>
              )}

              {response.role === "assistant" && (
                <>
                  <div className="message">
                    <div style={{ textAlign: "left", fontWeight: "bolder" }}>Kick Helper</div>
                    {response.message !== questions && (
                      <div style={{ textAlign: "left", marginTop: "0.5rem", fontWeight: "lighter" }}>{response.message}</div>
                    )}

                    <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "1rem" }}>
                      <button className="LoginButton">👍</button>
                      <button className="LoginButton">👎</button>
                      <button className="LoginButton">❤️</button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {!ask && responses.length === 0 && (
            <div style={{ textAlign: "center", color: "white", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, padding: "2rem" }}>
              <div style={{ textTransform: "none" }} className="LoginTitle">Hi, I am <span className="users_username">KickAI</span></div>
              <div style={{ padding: "1rem" }}>Cost: <span className="users_username">15 SHARDS</span></div>
              <div>You can ask me anything about streaming!</div>
            </div>
          )}


          {thinking && responses.length === 0 && (
            <div className="message" style={{ textAlign: "center", color: "white", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, padding: "2rem", background: "transparent", alignContent: "center" }}>
              <div>
                KickAI is thinking...
              </div>
              <div>
                <img className="kick_thinking" alt="" height={55} src={kickLogo}></img>
              </div>
            </div>
          )}
        </div>

        {confirmAIBuy === true && (
          <div style={{ width: "100%", display: "grid", position: "absolute", left: 0, top: 0, bottom: 0, right: 0, backdropFilter: "blur(10px)" }}>
            <div className="confirmBuy">
              <div className="LoginTitle">
                Cost: 15 Shards
              </div>

              <div className="LoginTitle">
                Confirm?
              </div>

              <div style={{ display: "grid", alignItems: "center", gap: "1rem" }}>
                <button style={{ padding: "1rem", background: "lime", color: "black", fontWeight: "bolder" }} onClick={() => kickinitiate()} className="LoginButton">Confirm</button>
                <button onClick={() => setConfirmAIBuy(false)} className="LoginButton">Cancel</button>
              </div>
            </div>
          </div>
        )}

        <div className="question_section">
          <input placeholder="How can I improve my streaming?" onChange={(x) => setAsk(x.target.value)} type="text" className="AskKickHelper"></input>

          <button className="ask_button LoginButton" onClick={() => setConfirmAIBuy(true)}>Ask</button>
        </div>
      </div>
    </>
  )
}
