import "./styles/main.css";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import Login from "./components/Login";
import UserBalance from "./components/UserBalance";
import KickHelper from "./components/KickHelper";
import StreamerStats from "./components/StreamerStats";

// import { checkServer, fetchUserInfo } from "./server";
import Navbar from "./components/Navbar";
import Store from "./components/Store";
import AddItems from "./components/AddItems";

function App() {
  const [user, setUser] = useState("");
  const [balance, setBalance] = useState(0);
  const [claimed, setClaimed] = useState("");
  const [pfp, setPfp] = useState("");
  const [initial, setInitial] = useState(false);
  const [viewAI, setViewAI] = useState(false);
  const [previousLivestreams, setPreviousLivestreams] = useState([]);

  const [viewItem, setViewItem] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemCost, setItemCost] = useState("");
  const [itemImage, setItemImage] = useState("");
  const [itemId, setItemId] = useState("");

  useEffect(() => {
    document.title = "Home | DevGwardo";
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add-item" element={<AddItems />} />
      </Routes>
    </BrowserRouter>
  );

  function Home() {

    return (
      <div className="background">
        {!user && (
          <Login
            setUser={(x) => setUser(x)}
            setUserImage={(x) => setPfp(x)}
            setUserBalance={(x) => setBalance(x)}
            setInitialClaim={(x) => setInitial(x)}
            setLastClaim={(x) => setClaimed(x)}
            setPreviousStreams={(x) => setPreviousLivestreams(x)}
          />
        )}

        {user && (
          <>
            <Navbar
              user={user}
              pfp={pfp}
              balance={balance}
              claimed={claimed}
              initial={initial}
            />
            <Profile />
          </>
        )}
      </div>
    )
  }

  function Profile() {

    return (
      <>
        {!viewAI && (
          <div className="background">
            <>
              {viewItem === false && (
                <>
                  <div className="welcome">
                    <img alt="" style={{ borderRadius: "50%", maxHeight: "100px", marginLeft: "auto", marginRight: "auto", display: "grid" }} src={pfp}></img>

                    <div style={{ maxWidth: "250px", marginLeft: "auto", marginRight: "auto" }} className="LoginTitle">
                      Welcome to the next generation of rewards, <span className="users_username">{user}</span>!
                    </div>

                    <UserBalance
                      kickUser={user}
                      initialClaim={initial}
                      balance={balance}
                      lastClaim={claimed}
                      setUserBalance={(x) => setBalance(x)}
                      setInitialClaim={(x) => setInitial(x)}
                      setLastClaim={(x) => setClaimed(x)}
                    />

                    <div>
                      <button onClick={() => setViewAI(!viewAI)} style={{ background: "lime", cursor: "pointer", color: "black", padding: ".5rem", borderRadius: "1rem", fontWeight: "bolder", textTransform: "uppercase", border: "transparent" }}>
                        <div>Kick AI Assistant</div>
                      </button>
                    </div>

                    {/* <StreamerStats user={user} stats={previousLivestreams} /> */}
                  </div>

                  <Store
                    setItemName={(x) => setItemName(x)}
                    setViewItem={(x) => setViewItem(x)}
                    setItemCost={(x) => setItemCost(x)}
                    setItemImage={(x) => setItemImage(x)}
                    setItemId={(x) => setItemId(x)}
                  />
                </>
              )}

              {viewItem === true && (
                <div style={{ background: "#313131", position: "absolute", left: 0, right: 0, top: 0, bottom: 0, width: "100%", height: "100%", zIndex: 2, display: "grid", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                  <div style={{ background: "#515151", borderRadius: "1rem", maxWidth: "350px", padding: "1rem" }}>
                    <div style={{ color: "white", fontWeight: "bolder", textAlign: "center", fontSize: "1.25rem", textTransform: "uppercase" }}>Enter Raffle</div>

                    <div style={{ textAlign: "center" }}>
                      <div style={{ color: "white", padding: "1rem", borderRadius: "1rem" }}>{itemName}</div>
                      <img style={{ display: "grid", justifyContent: "center", alignItems: "center", alignContent: "center", marginLeft: "auto", marginRight: "auto", borderRadius: "1rem" }} alt="" height={150} src={itemImage}></img>

                      <div style={{ textAlign: "center", color: "white", textTransform: "uppercase", padding: "1rem", fontWeight: "bolder" }}>Confirm?</div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: ".25rem" }}>
                      <button onClick={() => alert("Coming Soon!")} style={{ margin: ".25rem", padding: "1rem" }} className="LoginButton">Confirm</button>
                      <button onClick={() => setViewItem(false)} style={{ margin: ".25rem", padding: ".75rem", background: "red" }} className="LoginButton">Cancel</button>
                    </div>
                  </div>
                </div>
              )}

              <div style={{ padding: "1rem" }}></div>
            </>
          </div>
        )}

        {viewAI && (<KickAI />)}
      </>
    )
  }

  function KickAI() {

    return (
      <>
        <div>
          <KickHelper setBalance={(x) => setBalance(x)} user={user} setViewAI={(x) => setViewAI(x)} />
        </div>
      </>
    )
  }
}

export default App;
