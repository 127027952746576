import "../styles/store.css";
import React, { useState, useEffect } from 'react';
import { viewStore, addItemToStore } from "../server";

import crystal from "../assets/500px_Crystal.png";

export default function AddItems() {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [cost, setCost] = useState("");
  const [raffleId, setRaffleId] = useState("");
  const [storeItems, setStoreItems] = useState([]);

  async function getStoreItems() {
    const store = await viewStore()
    console.log(store)
    setStoreItems(store)
  }

  async function putItemInStore() {
    const itemAdded = await addItemToStore(
      image,
      name,
      cost,
      raffleId,
    )

    console.log(itemAdded)
    setImage("");
    setName("");
    setCost("");
    setRaffleId("");
  }

  useEffect(() => {
    getStoreItems()
  }, [])

  return (
    <div style={{ display: "grid", alignItems: "center", alignContent: "center", minHeight: "100dvh", background: "#313131" }}>
      <div
        style={{ display: "grid", justifyContent: "center", fontSize: "2rem", color: "white", padding: "1rem", fontFamily: "DM Sans, sans-serif" }}
      >Add Item To Raffles</div>

      <input
        onChange={(x) => setImage(x.target.value)}
        className="ItemInput"
        placeholder="Image"></input>
      <input
        onChange={(x) => setName(x.target.value)}
        className="ItemInput"
        placeholder="Name"></input>
      <input
        onChange={(x) => setCost(x.target.value)}
        className="ItemInput"
        placeholder="Cost"></input>
      <input
        onChange={(x) => setRaffleId(x.target.value)}
        className="ItemInput"
        placeholder="Raffle ID"></input>

      <div style={{ border: `2px lime solid`, color: "white", marginLeft: "auto", marginRight: 'auto', minWidth: "350px" }} className="store_card">
        <div style={{ backgroundImage: `url("${image}")` }} className="CardTopDisplay">
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
            <button title={"Raffle ID"} className="Detail_Button">{raffleId && raffleId}</button>
            <button title={"Terms & Conditions"} className="Detail_Button">T&C</button>
          </div>

          <ItemName item={name} />
        </div>

        <Button amount={cost} id={raffleId} />
      </div>

      <div style={{ display: "grid", maxWidth: "100%", justifyContent: "center" }}>
        <button onClick={() => putItemInStore()} className="LoginButton">Submit Item</button>
      </div>

      <div style={{ margin: "1rem", borderRadius: "1rem", padding: "1rem" }}>
        <div className="Store_Header">Current Store Items</div>
        <div className="store_map">
          {storeItems && storeItems.map(item =>
            <>
              <div style={{ border: `2px ${item.active ? "lime" : "red"} solid` }} className="store_card">
                <div style={{ backgroundImage: `url("${item.image}")` }} className="CardTopDisplay">
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <button title={"Raffle ID"} className="Detail_Button">{item?.raffle_id}</button>
                    <button title={"Terms & Conditions"} className="Detail_Button">T&C</button>
                  </div>

                  <ItemName item={item.item} />

                  <div style={{ display: "grid", justifyContent: "center", background: "#212121a1", marginLeft: "auto", marginRight: "auto", alignItems: "center", alignContent: 'center', padding: "1rem" }}>You have not entered yet.</div>
                </div>

                <Button amount={item.amount} id={item.raffle_id} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )

  function Button(props) {

    return (
      <div style={{ display: "flex", justifyContent: "space-around", padding: "0.5rem", gap: "1rem" }}>
        <button style={{ width: "75%", color: "white", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bolder", fontSize: "15px" }} className="LoginButton">Enter
          <img alt="" height={25} src={crystal}></img>
          {props.amount}
        </button>

        <button style={{ width: "25%", display: "grid", justifyContent: "center", alignContent: "center" }} className="LoginButton">❤️</button>
      </div>
    )
  }

  function ItemName(props) {

    return (
      <div className="StoreCardTitle">
        <div style={{ fontWeight: 1000 }}>{props.item}</div>
      </div>
    )
  }
}
