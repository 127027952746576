import "../styles/login.css";
import React, { useState } from 'react';
import axios from "axios";
import { fetchUserInfo, isDiscordLinked } from "../server";

import kick_logo from "../assets/kick_logo.svg";

export default function Login(props) {
  const [user, setUser] = useState("");
  const [kickUser, setKickUser] = useState("");
  const [kick, setkickLinked] = useState(false);
  const [code, setDiscordCode] = useState("");

  async function getKickUser() {
    await axios.get(`https://kick.com/api/v1/channels/${user}`).then(async (data) => {
      checkForUser(data.data.user);
    })
  }

  async function checkForUser(user) {
    const data = await fetchUserInfo(user);

    console.log(data)
    setKickUser(data.username);
    setkickLinked(data.kickLinked);
    setDiscordCode(data.kickCode);

    await checkDiscord(data.username);
  }

  async function checkDiscord(user) {
    const checkLinked = await isDiscordLinked(user);

    if (checkLinked.kickLinked === true) {
      await axios.get(`https://kick.com/api/v1/channels/${user}`).then(async (data) => {
        props.setUser(user);
        props.setUserBalance(checkLinked.balance);
        props.setUserImage(checkLinked?.kickUser?.user?.profile_pic);
        props.setLastClaim(checkLinked?.lastClaim);
        props.setInitialClaim(checkLinked?.initialClaim);
        const livestreams = data.data.previous_livestreams.sort((a, b) => a.viewer_count - b.viewer_count)
        props.setPreviousStreams(livestreams);
      })
    } else {
      setkickLinked(false);
    }
  }

  return (
    <div style={{ display: "grid", height: "100dvh" }}>
      <div className="Login">
        {!kickUser && (
          <>
            <img alt="" className="kick_logo" src={kick_logo}></img>
            <div className="LoginTitle">Kick Login</div>
            <input className="username" onChange={(x) => setUser(x.target.value)}></input>
            <button className="LoginButton" onClick={() => getKickUser()}>Sign In</button>
          </>
        )}

        {kickUser && kick === false && (
          <div className="LoginTitle">
            <div style={{ padding: "1rem" }}>Link your Kick!</div>
            <div className="code_display">
              {code}
            </div>

            <div style={{ display: "grid", gap: "1rem", padding: "1rem" }}>
              <div>1. Copy code</div>

              <div>2. Use command !verify "code"</div>
            </div>

            <button onClick={() => checkDiscord(kickUser)} className="LoginButton">Check Status</button>
          </div>
        )}
      </div>
    </div>
  )
}
