import "../styles/main.css";
import "../styles/login.css";
import React from 'react';
import { claimBonus } from "../server";
import moment from "moment";

import crystal from "../assets/Crystal Spin.gif";

export default function UserBalance(props) {

  async function userClaim() {
    const claim = await claimBonus(props.kickUser);

    if (claim) {
      props.setInitialClaim(true)
      props.setUserBalance(claim.balance)
      props.setLastClaim(claim.lastClaim.seconds)
    } else {
      props.setInitialClaim(false)
    }
  }

  return (
    <div style={{ display: "flex", borderRadius: "2rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", alignItems: 'center', marginLeft: "auto", marginRight: "auto" }}>
        {props.initialClaim === false && (
          <div style={{ display: "grid", marginBottom: "1rem", background: "#313131", borderRadius: "1rem", padding: "1rem", color: "white" }}>
            <div style={{ textAlign: "center", padding: ".5rem", textTransform: "uppercase" }}>New Member Claim</div>
            <button onClick={() => userClaim()} style={{ margin: "1rem" }} className="LoginButton">Claim Bonus</button>
          </div>
        )}

        <div className="user_balance">
          <div style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "center", fontSize: "3rem" }}>
            <div style={{ color: "lime", fontSize: "2rem", fontWeight: "bolder" }} className="LoginTitle">
              {props.balance}
            </div>

            <div className="LoginTitle" style={{ fontWeight: "light", fontSize: "2rem", display: "flex", alignItems: "center" }}>
              <img height={55} style={{ padding: 0, marginRight: -10, marginLeft: -20 }} alt="" src={crystal}></img> <span className="users_username">SHARDS</span>
            </div>
          </div>

          {/* 
          <div style={{ padding: ".5rem", color: "lime" }}>
            <hr></hr>
          </div>

          {props.lastClaim && (
            <div style={{ textAlign: "center", padding: ".5rem" }}>
              <div style={{ fontWeight: "bolder", fontSize: "1rem" }}>Last Claimed</div>
              <div style={{ fontWeight: "bolder", fontSize: ".75rem" }}>
                {moment(props.lastClaim * 1000).format("ddd, MMM Do YYYY")}
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}
