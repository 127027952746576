import "../styles/navbar.css";
import React from 'react';

export default function Navbar(props) {
  return (
    <div className="navbar">
      <button className="nav-button">
        <div>{props.user}</div>
        <img alt="" className="pfp" src={props.pfp}></img>
      </button>
    </div >
  )
}
